import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppRoutesModule } from './app-routes.module'
import { CoreModule } from '@src/core/core.module'
import { AppComponent } from './app.component'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { provideIonicAngular } from '@ionic/angular/standalone'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { ToastModule } from 'primeng/toast'
import { MessageService } from 'primeng/api'
import { RouteReuseStrategy } from '@angular/router'

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, IonicModule.forRoot(), AppRoutesModule, CoreModule, ToastModule],
  bootstrap: [AppComponent],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({ mode: 'md', keyboardHeight: 320 }),
    provideHttpClient(withInterceptorsFromDi()),
    MessageService,
  ],
})
export class AppModule {}
