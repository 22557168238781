import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import { AuthComponent } from '@src/layouts/mobile/auth/auth.component'
import { AuthGuard } from '@src/core/guards/auth.guard'
import { LayoutsModule } from '@src/layouts/mobile/layouts.module'

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthComponent,
    data: { roles: ['stock_operator'] },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'accueil',
        loadChildren: () => import('./views/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'depots',
        loadChildren: () => import('./views/warehouse/warehouse.module').then((m) => m.WarehouseModule),
      },
      {
        path: 'operations',
        loadChildren: () => import('./views/operations/operations.module').then((m) => m.OperationsModule),
      },
    ],
  },
  { path: '**', redirectTo: 'accueil' },
]

@NgModule({
  imports: [LayoutsModule, RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutesModule {}
