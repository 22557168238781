import { HttpClient } from '@angular/common/http'
import { Component } from '@angular/core'
import { ActivatedRoute, UrlSegment } from '@angular/router'
import { AuthService } from '@src/core/services/auth.service'
import { TitleService } from '@src/core/services/title.service'
import { Environment } from '@src/environments/environment'
import { setStorageJSON } from '@src/helpers/storage.helper'
import { addIcons } from 'ionicons'
import { logOutSharp, personSharp } from 'ionicons/icons'
import { KeycloakProfile } from 'keycloak-js'

@Component({
  selector: 'app-ionic-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  public loggedIn: boolean = false
  public user: KeycloakProfile = {}
  public logoMenu: string | null = Environment.LOGO
  public titleMenu: string | null = Environment.TITLE
  public headerMenuItems: Array<{ label: string; icon: string; action: () => void }> = []
  public currentRoute: UrlSegment | null = null
  public pageTitle: string | null = null

  constructor(
    private readonly auth: AuthService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    addIcons({ personSharp, logOutSharp })
  }

  async ngOnInit(): Promise<void> {
    this.route.children[this.route.children.length - 1].url.subscribe((data) => {
      this.currentRoute = data[data.length - 1]
    })

    this.titleService.setTitle.asObservable().subscribe((title) => {
      this.pageTitle = title
    })

    this.loggedIn = await this.auth.isLoggedIn()

    if (this.loggedIn) {
      this.user = await this.auth.loadUserProfile()

      this.http.get('/api/v1/me').subscribe((data) => {
        setStorageJSON('currentUser', data)
      })

      this.headerMenuItems = [
        {
          label: this.user.email ?? 'Utilisateur',
          icon: 'person-sharp',
          action: () => {},
        },
        {
          label: 'Déconnexion',
          icon: 'log-out-sharp',
          action: () => this.onLogout(),
        },
      ]
    }
  }

  public onLogout(): void {
    this.auth.logout()
  }
}
