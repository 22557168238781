<ion-app>
    <ion-header [translucent]="true">
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-menu-button></ion-menu-button>
            </ion-buttons>
            <ion-title>{{ pageTitle }}</ion-title>
            <ion-buttons slot="end" *ngIf="loggedIn">
                <ion-button *ngFor="let item of headerMenuItems" (click)="item.action()">
                    <ion-icon *ngIf="item.icon" [name]="item.icon"></ion-icon>
                    <ion-label>{{ item.label }}</ion-label>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <ion-menu contentId="main-content">
        <ion-header>
            <ion-toolbar>
            </ion-toolbar>
        </ion-header>
        <ion-content [fullscreen]="true">
            <ion-list>
                <ion-item [button]="true" routerLink="/accueil">
                    <ion-icon slot="start" name="home-outline"></ion-icon>
                    <ion-label>Accueil</ion-label>
                </ion-item>
                <ion-item [button]="true" routerLink="/depots">
                    <ion-icon slot="start" name="list-outline"></ion-icon>
                    <ion-label>Dépots</ion-label>
                </ion-item>
                <ion-item [button]="true" routerLink="/operations">
                    <ion-icon slot="start" name="construct-outline"></ion-icon>
                    <ion-label>Opérations</ion-label>
                </ion-item>
                <ion-item [button]="true">
                    <ion-icon slot="start" name="car-outline"></ion-icon>
                    <ion-label>Livraison</ion-label>
                </ion-item>
                <ion-item [button]="true">
                    <ion-icon slot="start" name="checkmark-circle-outline"></ion-icon>
                    <ion-label>Récupération</ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-menu>

    <!-- set ion-content and ion-footer from ion-router-outlet -->
    <ion-router-outlet class="mt-8" id="main-content"></ion-router-outlet>
</ion-app>